import React, { useEffect, useState } from 'react';
import { SEO, VideoBackground, Logo } from 'components';
import './thank-you.scss';

const Download = (props) => {
  const [downloadLink, setDownloadLink] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const link = queryParams.get('link');
    setDownloadLink(link);
  }, []);

  const siteName = 'Clean Vibes';
  return (
    <div className="thanks">
      <SEO title={`Download | ${siteName}`} />

      <VideoBackground
        className="thanks-video"
        url="https://cleanvibes.cdn.prismic.io/cleanvibes/b40c09fb-3577-48e9-9041-500de3d62e86_Clean+Vibes+-+Event+Cleaning+Sydney.mp4"
      />

      <div className="thanks-text-container">
        <div className="thanks-card">
          <Logo className="thanks-logo" />
          <div className="thanks-text">
            Click the link below to download the file
            <br />
            <span style={{ fontWeight: 700, fontSize: '12px', textAlign: 'center' }}>
              (Warning: You can only download this file once, so be sure to store it somewhere safe)
            </span>
          </div>
          <div className="thanks-link">
            <a href={downloadLink} className="download-link">
              Download File
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
